import React from "react";
import { Jumbotron } from "react-bootstrap";
import Layout from "../components/layout";
import PortableText from "../components/portableText";
import SEO from "../components/seo";
import SpotlightLayout2 from "../components/SpotlightLayout2";

export const query = graphql`
  query SpecificationDocumentWalkthroughTemplateQuery($id: String!) {
    specificationDocumentWalkthrough: sanitySpecificationDocumentWalkthrough(id: { eq: $id }) {
      id
      title
      VideoID
      audioID
      _rawNotes(resolveReferences: { maxDepth: 2 })
      _rawText(resolveReferences: { maxDepth: 2 })
      _rawQAndA(resolveReferences: { maxDepth: 2 })
      network {
        title
        brandColors
        slug {
          current
        }
      }
    }
  }
`;

const SpecificationDocumentWalkthrough = (props) => {
  const { spotlightLayoutResource } = props.pageContext;

  const { specificationDocumentWalkthrough } = props.data;

  const {
    VideoID,
    audioID,
    _rawImage,
    _rawText,
    _rawQAndA,
    title,
    network,
  } = specificationDocumentWalkthrough;

  return (
    <Layout>
      <SEO
        title={`${title} | ${network.title}`}
        description={`Learn why ${network.title} is relevant, how it works and how you can get involved. All with super simple explanations!`}
        image={_rawImage}
      />
      <SpotlightLayout2 resource={spotlightLayoutResource}>
        <>
          <h1 className="mb-3">{title} Walkthrough</h1>
          {specificationDocumentWalkthrough ? (
            <div className="mb-3">
              {VideoID && (
                <iframe
                  width="100%"
                  height="600px"
                  frameborder="0"
                  modestbranding="0"
                  showinfo="0"
                  id="video"
                  src={`https://www.youtube.com/embed/${VideoID}`}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              )}
              {audioID && (
                <div className="mb-3">
                  <iframe
                    src={`https://anchor.fm/rebase/embed/episodes/${audioID}`}
                    height="102px"
                    width="100%"
                    frameborder="0"
                    scrolling="no"
                    id="audio"
                  ></iframe>
                </div>
              )}
              {/* {_rawNotes && (
                <div className="mb-3">
                  <Link
                    to={getLink(network.slug.current, "specificationDocumentWalkthrough", title)}
                  >
                    See raw notes
                  </Link>
                </div>
              )} */}
              <div id="text" className="mx-auto" style={{ maxWidth: "60ch" }}>
                <PortableText blocks={_rawText} />
              </div>
            </div>
          ) : (
            <Jumbotron className="text-center">No Whitepaper Walkthrough available yet</Jumbotron>
          )}
          {_rawQAndA && (
            <div className="mb-3 mx-auto" style={{ maxWidth: "60ch" }}>
              <h2 id="Q&A">Q&A</h2>
              <PortableText blocks={_rawQAndA} />
            </div>
          )}
        </>
      </SpotlightLayout2>
    </Layout>
  );
};

export default SpecificationDocumentWalkthrough;
